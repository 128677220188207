@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Poppins;
}
