.btn-style {
  /*margin-left: 80%; */
  background: linear-gradient(181.33deg, #1E7FF0 1.13%, rgba(28, 151, 240, 0.5) 98.86%);
  height:75px;
  width:210px;
}

.btn-style-secondary {
    /*margin-left: 80%; */
    
    background-color: transparent;
    border: 5px solid;
    border-color: linear-gradient(181.33deg, #1E7FF0 1.13%, rgba(28, 151, 240, 0.5) 98.86%);
    height:75px;
    width:210px;
  }

.text-btn {
    font-size: 150%;
}

.first-main-p1{
    font-size: 48px;
    font-weight: 700;
}
.first-main-p2{
    font-size: 18px;
}
.job-seeker-p1{
 font-size: 32px;
 font-weight: 700;   
}
.job-seeker-p2{
    font-size: 22px;
}
.logo-image{
    height: 80px;
    width: 80px;
}
.text-color{
    background-image: linear-gradient(181.33deg, #1E7FF0 1.13%, rgba(28, 151, 240, 0.5) 98.86%);
    background-clip: text;
    color: transparent;
}
.card-style-employer{
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #0A2A3D 0%, #051D2A 100%);
    border-radius: 8%;
}
.card-style-jobseeker{
    width: 45%;
    height: 100%;
    background: linear-gradient(180deg, #0A2A3D 0%, #051D2A 100%);
    /* box-shadow: 0px 3px 34px 4.53333px rgba(53, 62, 75, 0.05); */
    border-radius: 8%;
}
.card-text{
    font-size: none;
}
.footer-p1{
    font-size: 35px;
}
.footer-p2{
    font-size: 24px;
}
@media screen and (max-width: 768px) {
    .btn-style {
        height: 50px;
        width: 140px;
    }

    .btn-style-secondary {
        height: 50px;
        width: 140px;
    }

    .text-btn{
        font-size: 100%;
    }
    .first-main-p1{
        font-size: 24px;
        font-weight: 700;
    }
    .first-main-p2{
        font-size: 10px;
    }
    .job-seeker-p1{
        font-size: 16px;
        font-weight: 700;   
    }
    .job-seeker-p2{
        font-size: 10px;
    }
    .logo-image{
        height: 40px;
        width: 40px;
    }
    .card-style{
        width: 100%;
    }
    .card-text{
        font-size: 12px;
    }
    .footer-p1{
        font-size: 17px;
    }
    .footer-p2{
        font-size: 12px;
        text-transform: capitalize;
    }

    .footer-p2-email{
        font-size: 12px;
    }
    
    .carousel{
        width:auto;
        height:auto;
    }

    .job-seeker-img{
        width: 100%;
    }
}

.footer{
    background: linear-gradient(180deg, #133245 0%, rgba(19, 50, 69, 0) 100%);
}

